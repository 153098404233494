@import "../../../../../global-ui-scss/variables";

.meetingRow {
  :global(.ant-picker) {
    transform: translate(-27px, -12px);
  }
}

.row {
  display: flex;
  gap: 8px;
  align-items: center;
}

.root {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    height: 34px;
  }

  .title {
    color: var(--primary-dark, #003e47);
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%; /* 26.4px */
  }

  .filterRow {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 24px;
  }

  .auditProgress {
    width: 352px;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background: var(--primary-white, #fff);
    padding: 12px 16px;

    .field {
      display: flex;
      flex-direction: column;
      gap: 12px;

      + .field {
        margin-top: 24px;
      }

      .key {
        color: var(--neutrals-dark-40, rgba(0, 62, 71, 0.4));
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .row {
        display: flex;
        gap: 12px;
      }
    }

    .subtitle {
      color: var(--neutrals-dark-40, rgba(0, 62, 71, 0.4));
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .progressBlock {
      margin-top: 10px;
      display: flex;
      flex-direction: column;

      > span {
        color: var(--secondary-green-700, #6db333);
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .progressLine {
        width: 100%;
        border-radius: 1px;
        background: #e5eced;
        height: 4px;
        margin-top: 10px;

        .progressFill {
          height: 100%;
          background: var(--secondary-green-700, #6db333);
        }
      }
    }

    .nextMeeting {
      display: flex;
      flex-direction: column;
      margin-top: 26px;
      gap: 10px;

      .meetingRow {
        display: flex;
        justify-content: space-between;

        .key {
          color: var(--neutrals-dark-60, rgba(0, 62, 71, 0.6));
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .value {
          color: var(--neutrals-dark-60, rgba(0, 62, 71, 0.6));
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
  }

  .statusGroups {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .addNew {
    width: 100%;
    cursor: pointer;
    padding-block: 4px;
    height: 24px;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
