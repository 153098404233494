@import "../../global-ui-scss/mixins";
@import "../../global-ui-scss/variables";

.wrapper {
  border: 1px solid #dae3e5;
  border-radius: 4px;
}

.topContent {
  display: grid;
  grid-template-columns: 10fr 12fr;
  grid-gap: 1rem;
  padding: 0.5rem;
}

.topLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-block: 0.5rem;
}

.bottomGradient {
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.5rem 1rem;
  background: linear-gradient(
    90.38deg,
    #49baca 0.33%,
    #245c64 20.61%,
    #49baca 40.89%,
    #488993 61.17%,
    #7ce1ef 94.63%,
    #49baca 101.73%
  );
}

.topRight {
  position: relative;
}

.image,
.canvas {
  min-width: 0;
  width: 240px;
  height: 146px;
}

.image {
  object-fit: contain;
  user-select: none;
  position: absolute;
  right: 0;
  top: 0;
  pointer-events: none;
}

.title {
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 0.25rem;
}

.companyName {
  font-weight: 700;
  font-size: 0.875rem;
  color: RgbaColor($primary-dark, 0.8);
}

.date {
  color: RgbaColor($primary-dark, 0.6);
}
