@import "../../../global-ui-scss/mixins";
@import "../../../global-ui-scss/variables";

.downloadLink {
  text-decoration: none;
}

.downloadButton {
  padding: 0.25rem 1rem;
  grid-gap: 0.625rem;
  text-decoration: none;
}

.fileNameWrapper {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  margin-bottom: 1rem;
}

.fileName {
  font-size: 1rem;
}
