.main-wrapper-pdf {
  z-index: 101;
}

.main-wrapper-pdf .document-header-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.main-wrapper-pdf .document-header-actions .being-signed {
  color: var(--primary-blue, #49baca);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 4px;
  border: 1px solid var(--primary-blue, #49baca);
  background: rgba(73, 186, 202, 0.1);
  padding: 8.75px 16px;
}

.pdf-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navigation-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}

.navigation-button {
  color: black;
}

.main-wrapper-pdf .first-block {
  position: sticky;
  z-index: 10;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  background: #ffffff;
  border-bottom: 1px solid #ebf0f0;
}

.main-wrapper-pdf .first-block .buttonExit-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 24px;
  font-weight: 800;
  font-size: 24px;
  color: #003e47;
}

.main-wrapper-pdf .first-block .status-block {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  background: #6db333;
  border-radius: 4px;
  padding: 10px 46px;
}

.main-wrapper-pdf .content-block {
  display: grid;
  grid-template-columns: 360px 1fr;
  background: linear-gradient(0deg, rgba(0, 62, 71, 0.05), rgba(0, 62, 71, 0.05)), #ffffff;
}

.main-wrapper-pdf .content-block .info-block {
  position: sticky;
  top: 83px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 83px);
}

.main-wrapper-pdf .content-block .info-block .info-block-row {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main-wrapper-pdf .content-block .info-block .pd-status {
  padding: 21px 24px 17px 24px;
  border-top: 1px solid #ebf0f0;
}

.main-wrapper-pdf .content-block .info-block .pd-by {
  padding: 15px 24px;
}

.main-wrapper-pdf .content-block .info-block .info-block-row .block-by {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  align-items: center;
}

.main-wrapper-pdf .content-block .info-block .info-block-row .block-by .block-by-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.main-wrapper-pdf
  .content-block
  .info-block
  .info-block-row
  .block-by
  .block-by-content
  .block-first-letter {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  background: #49baca;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-wrapper-pdf
  .content-block
  .info-block
  .info-block-row
  .block-by
  .block-by-content
  .block-first-letter {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.main-wrapper-pdf .content-block .info-block .info-block-row .block-by .signature-status {
  padding: 2px 8px;
  border-radius: 25px;
  font-size: 12px;
  font-weight: 700;
  margin-top: 0;
}

.main-wrapper-pdf .content-block .info-block .info-block-row .block-bordered {
  justify-content: space-between;
  border-bottom: 1px solid var(--neutrals-solid-dark-5, #f2f5f6);
  padding: 8px 0;
}

.main-wrapper-pdf .content-block .info-block .info-block-row .block-by:last-child.block-bordered {
  border-bottom: none;
}

.main-wrapper-pdf .content-block .info-block .info-block-row .block-by .signature-status {
  padding: 2px 8px;
  border-radius: 25px;
  font-size: 12px;
  font-weight: 700;
  text-transform: capitalize;
}

.main-wrapper-pdf .content-block .info-block .info-block-row .block-by .signature-status.unsigned {
  color: #f17f16;
  background: rgba(241, 127, 22, 0.1);
  margin-left: 10px;
}

.main-wrapper-pdf .content-block .info-block .info-block-row .block-by .signature-status.failed {
  color: #ff5454;
  background: rgba(255, 84, 84, 0.1);
  margin-left: 10px;
}

.main-wrapper-pdf
  .content-block
  .info-block
  .info-block-row
  .block-by
  .signature-status.in_progress {
  color: #49baca;
  background: rgba(73, 186, 202, 0.2);
  margin-left: 10px;
}

.main-wrapper-pdf .content-block .info-block .info-block-row .block-by .signature-status.signed {
  color: #6db333;
  background: rgba(109, 179, 51, 0.2);
  margin-left: 10px;
}

.main-wrapper-pdf .content-block .info-block .info-block-row .block-by .block-first-letter {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  background: #49baca;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-wrapper-pdf .content-block .info-block .info-block-row .block-by .block-first-letter {
  width: 24px;
  height: 24px;
}

.main-wrapper-pdf .content-block .info-block .info-block-row .ready-button {
  border-radius: 25px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #6db333;
  background-color: rgba(109, 179, 51, 0.1);
  width: max-content;
  padding: 6px 9px;
  cursor: pointer;
}

.main-wrapper-pdf .content-block .info-block .info-block-row .missing-button {
  color: #f17f16;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  background: rgba(241, 127, 22, 0.1);
  padding: 8px;
  cursor: pointer;
  border-radius: 25px;
  width: max-content;
}

.main-wrapper-pdf .content-block .info-block .info-block-row .title-table {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 62, 71, 0.4);
}

.main-wrapper-pdf .content-block .info-block .info-block-row .content-table,
.main-wrapper-pdf .content-block .info-block .info-block-row .block-by .content-table {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 62, 71, 0.8);
  display: flex;
  align-items: center;
}

.main-wrapper-pdf .content-block .info-block .info-block-row .block-by .content-table.signer-me {
  margin-left: 4px;
}
.main-wrapper-pdf .content-block .info-block .info-block-row .block-by .content-table.signer-me {
  margin-top: 0;
}
.main-wrapper-pdf .content-block .info-block .info-block-row .block-by .content-table.signer-name {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 187px;
  margin-top: 0;
}

.main-wrapper-pdf
  .content-block
  .info-block
  .info-block-row
  .block-by
  .content-table.signer-name.my-name {
  max-width: 169px;
}

.main-wrapper-pdf .content-block .info-block .info-block-row .content-table svg {
  margin-right: 4px;
}

.main-wrapper-pdf .content-block .info-block .button-arrows {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 15px;
  margin: 24px;
  padding: 8px 0;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #99b2b5;
  border: 1px solid #99b2b5;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.main-wrapper-pdf .pdf-block {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 40px;
  min-width: 0;
  padding: 40px;
  background: rgba(0, 62, 71, 0.05);
}

.pdf-view {
  overflow: auto;
}

.main-wrapper-pdf .pdf-block .pdf-view > span {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 62, 71, 0.4);
  text-align: center;
  display: block;
  margin-bottom: 12px;
}

.main-wrapper-pdf .pdf-block .listA4 {
  align-items: center;
  /*width: 45vw;*/
  width: max-content;
  height: max-content;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  margin-top: 16px;
}

.field-value {
  color: var(--neutrals-dark-80, rgba(0, 62, 71, 0.8));
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 136%;
  margin-bottom: 8px;
}

@media (max-width: 768px) {
  .main-wrapper-pdf {
    .content-block {
      display: flex;
      flex-direction: column;

      .info-block {
        position: static;
        height: auto;

        .comments-block {
          padding-bottom: 0;

          input {
            position: static;
          }
        }
      }
    }

    .pdf-block {
      padding: 1rem;

      .pdf-view {
        > div {
          width: 100% !important;
        }
      }
    }
  }
}
