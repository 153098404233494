.wrapper_overview {
  position: relative;
  flex-grow: 1;
  padding: 28px 80px;

  .status-progress-arrow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.wrapper_overview .overview_content .title {
  font-weight: 800;
  text-align: center;
  font-size: 24px;
  line-height: 27px;
  color: #003e47;
}

.wrapper_overview .overview_content {
  display: grid;
  grid-gap: 32px;
  height: max-content;
}

.wrapper_overview .overview_content .content_box {
  background: #fff;
  border-radius: 12px;
}

.wrapper_overview .overview_content .content_box .header {
  display: grid;
  grid-template-columns: 1fr max-content;
  padding: 24px;
  align-items: center;
  border-bottom: 1px solid rgba(0, 62, 71, 0.07);
  cursor: pointer;
}

.wrapper_overview .overview_content .content_box .header img {
  cursor: pointer;
}

.wrapper_overview .overview_content .content_box .header__title {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #003e47;
}

.wrapper_overview .overview_content .content_box .every_document {
  padding: 16px 22px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
}

.wrapper_overview .overview_content .content_box .every_document .every_document_left {
  display: flex;
  align-items: center;
  grid-gap: 12px;
}

.wrapper_overview .overview_content .content_box .every_document_right {
  display: flex;
  align-items: center;
  grid-gap: 15px;
}

.wrapper_overview .overview_content .content_box .every_document .title {
  font-size: 16px;
}

.wrapper_overview .overview_content .content_box .every_document .header__icon,
.wrapper_overview .overview_content .content_box .every_document .status {
  justify-self: end;
}

.wrapper_overview .overview_content .content_box .every_document .status_missing {
  border: 1px solid rgba(0, 62, 71, 0.4);
  box-sizing: border-box;
  border-radius: 25px;
  font-weight: bold;
  padding: 6px 9px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: rgba(0, 62, 71, 0.4);
}

.status_missing_ready {
  background-color: #6db333;
  border: 1px solid #6db333;
  box-sizing: border-box;
  border-radius: 25px;
  font-weight: bold;
  padding: 6px 9px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
}

.status_missing_ready span {
  color: white;
}

.wrapper_overview .overview_content .content_box .every_document .status_signed {
  background: #6db333;
  border-radius: 25px;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  padding: 6px 9px;
  color: #fff;
}

.wrapper_overview .overview_content .content_box .every_document:hover {
  background: rgba(0, 62, 71, 0.07);
}

.wrapper_overview .overview_content .audit_status_wrapper .body {
  padding: 28px 24px;
}

.title_sub {
  margin-top: 22px;
}

.body_inner {
  display: flex;
  justify-content: space-between;
  margin-bottom: 55px;
}

.wrapper_overview .overview_content .audit_status_wrapper .progress_bar .arrow .left,
.wrapper_overview .overview_content .audit_status_wrapper .progress_bar .arrow .right {
  position: absolute;
  width: 14px;
  height: 14px;
  background-color: var(--background-color);
}

.wrapper_overview .overview_content .audit_status_wrapper .progress_bar .arrow {
  width: calc(100% - 14px);
  height: 20px;
  position: relative;
  background-color: var(--background-color);
}

.wrapper_overview .overview_content .audit_status_wrapper .progress_bar .arrow .right {
  right: -7px;
  top: 50%;
  transform: rotate(45deg) translateY(-50%);
  transform-origin: center top;
  border-top-right-radius: 2px;
}

.wrapper_overview .overview_content .audit_status_wrapper .progress_bar .arrow .left {
  left: -7px;
  top: 50%;
  transform: rotate(45deg) translateY(-50%);
  transform-origin: center top;
  border-top-right-radius: 2px;
  background-color: white;
}

.wrapper_overview .overview_content .audit_status_wrapper .progress_bar {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
  color: #003e47cc;
  font-weight: 500;
}

.wrapper_overview .overview_content .audit_status_wrapper .progress_bar .progress_line > div {
  display: flex;
  margin-right: -14px;
  flex-direction: column;
  gap: 8px;
}
.wrapper_overview .overview_content .audit_status_wrapper .progress_bar .progress_line {
  display: grid;
  grid-gap: 5px;
}

.wrapper_overview .overview_content .audit_status_wrapper .progress_bar .progress_line svg,
.wrapper_overview .overview_content .audit_status_wrapper .progress_bar .progress_line svg path {
  width: 100%;
}

.wrapper_overview .overview_content .audit_status_wrapper .progress_bar img {
  grid-column: 1/-1;
  width: 100%;
  margin: 0 auto;
}

.wrapper_overview .overview_content .audit_status_wrapper .progress_bar span {
  display: block;
}

.wrapper_overview .overview_content .audit_status_wrapper .title {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #003e47;
  text-align: right;
}

.wrapper_overview .overview_content .audit_status_wrapper .description {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin: 8px 0 0;
  color: rgba(0, 62, 71, 0.6);
}

.wrapper_overview .overview_content .audit_status_wrapper .time .description {
  text-align: right;
}

.wrapper_overview .overview_content .audit_status_wrapper .line_progress {
  width: 142px;
}

.wrapper_overview .overview_content .audit_status_wrapper .progress_line .percent {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  margin: 0;
  color: #6db333;
  margin-top: 8px;
}

.wrapper_overview .request_wrapper .body {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 16px 32px;
}

.wrapper_overview .request_wrapper .request_wrapper__every {
  display: grid;
  grid-gap: 12px;
  justify-content: center;
  position: relative;
  justify-items: center;
  padding: 16px;
  text-decoration: none;
}

.wrapper_overview .request_wrapper .request_wrapper__every .request_preview {
  border-radius: 26px;
  width: 79px;
  height: 79px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(109, 179, 51, 0.2);
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  color: #6db333;
}

.wrapper_overview .request_wrapper .body .receive .request_preview {
  color: rgba(0, 62, 71, 0.8);
  background: rgba(0, 62, 71, 0.2);
  user-select: none;
}

.wrapper_overview .request_wrapper .body .sent .request_preview {
  background: rgba(73, 186, 202, 0.2);
  color: #49baca;
}

.wrapper_overview .request_wrapper .request_wrapper__every .message {
  position: absolute;
  z-index: 10;
  width: 32px;
  height: 25px;
  background: #f17f16;
  border: 3px solid #ffffff;
  border-radius: 51px;
  right: -10px;
  top: -4px;
  font-weight: 600;
  color: #fff;
  font-size: 16px;
  text-align: center;
  line-height: 18px;
}

.wrapper_overview .request_wrapper .request_wrapper__every p {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #003e47;
  margin: 0;
}

.wrapper_overview .meeting_wrapper .body {
  padding: 0 28px 0 28px;
}

.wrapper_overview .meeting_wrapper .body .meeting_schedule {
  display: flex;
  flex-direction: column;
}

.wrapper_overview .meeting_wrapper .body .meeting_schedule__every {
  text-decoration: none;
  padding: 24px 0 24px 0;
  display: flex;
  grid-gap: 8px;
  justify-content: space-between;
  align-items: center;
}

.wrapper_overview .meeting_wrapper .body .meeting_schedule__every .reminder {
  display: flex;
  grid-gap: 25px;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  margin-top: 8px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: rgba(0, 62, 71, 0.4);
}

.wrapper_overview .meeting_wrapper .body .meeting_schedule__every .reminder span {
  color: #003e4766;
  line-height: 14px;
  display: flex;
  align-items: center;
}

.wrapper_overview .meeting_wrapper .body .meeting_schedule__every .reminder svg {
  margin-right: 9px;
}

.wrapper_overview .meeting_wrapper .body .meeting_schedule__every .wrapper_person {
  display: flex;
  gap: 8px;
  flex-direction: row;
}

.wrapper_overview .meeting_wrapper .body .meeting_schedule__every .wrapper_person__every {
  background: #49baca;
  margin: 0 4px;
  display: grid;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  text-transform: uppercase;
}

.wrapper_overview .wrapper_tabs {
  display: grid;
  height: max-content;
  grid-gap: 50px;
}

.wrapper_overview .wrapper_tabs .tabs {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 40px;
}

.wrapper_overview .wrapper_tabs .tabs .tab {
  color: rgba(0, 62, 71, 0.4);
  font-weight: 800;
  font-size: 24px;
  cursor: pointer;
  line-height: 110%;
}

.wrapper_overview .wrapper_tabs .tabs .active {
  color: #003e47;
}

.wrapper_tabs .content__info .navigation_point {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #49baca;
  position: relative;
}

.wrapper_tabs .content__info .every_block_red .navigation_point {
  background-color: #ff5454;
}

.wrapper_tabs .content__info .every_block_grey .preview {
  color: rgba(0, 62, 71, 0.6);
}

.wrapper_tabs .content__info {
  display: grid;
  grid-gap: 36px;
}

.wrapper_tabs .content__info .every_block {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 14px;
  align-items: center;
}

.wrapper_tabs .content__info .navigation_point:before {
  position: absolute;
  content: "";
  display: block;
  width: 1px;
  z-index: 1;
  right: 3px;
  top: 10px;
  border-left: 1px dashed rgba(0, 62, 71, 0.2);
  height: 110px;
}

.wrapper_tabs .content__info .every_block:last-child .navigation_point:before {
  display: none;
}

.wrapper_tabs .content__info .every_block:nth-child(2) .navigation_point:before {
  height: 140px;
}

.wrapper_tabs .content__info .btn_bluelight {
  grid-column: 1/-1;
  margin: 0 24px;
  width: calc(100% - 48px);
  text-transform: none;
  font-size: 12px;
}

.wrapper_tabs .content__info .btn_border_delete {
  text-transform: none;
  width: max-content;
}

.wrapper_tabs .content__info p {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 62, 71, 0.6);
  grid-column: 1/-1;
  margin: 0 24px;
  width: calc(100% - 48px);
}

.preview {
  font-size: 14px;
}

.create-meeting-button_dashboard {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;
  font-weight: 700;
  color: #49baca;
}

.title_name {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #003e47;
}

.ready-button {
  color: #6db333;
}

.every_request {
  padding: 0 22px;
  height: 85px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-feature-settings: "pnum" on, "lnum" on;
}

.every_listRequest {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #003e47;
  padding-bottom: 5px;
  padding-top: 5px;
}

.header__title-req {
  margin: 10px 0;
}

.header_req {
  border-top: 1px solid rgba(0, 62, 71, 0.07);
  border-bottom: none !important;
}

.every_listRequest-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mt-5px {
  margin-top: 5px;
}

.every_listRequest-sub {
  margin-top: 5px;
  display: flex;
  align-items: center;
  grid-gap: 15px;
}

.count-length {
  font-feature-settings: "pnum" on, "lnum" on;
  font-family: "Roboto", sans-serif;
}

.every_request-status {
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: #003e47cc;
  border: 1px solid #003e47cc;
  border-radius: 25px;
  padding: 6px 9px;
  text-transform: capitalize;
  margin-top: 4px;
}

.every_request:hover {
  background: rgba(0, 62, 71, 0.07);
  border-radius: 10px;
}
