@import "../../../../../global-ui-scss/mixins";
@import "../../../../../global-ui-scss/variables";

.wrapper {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  padding-top: 1.5rem;
  border-top: 1px solid #ebf0f0;
}

.list {
  max-height: 300px;
  overflow: auto;
}

.title {
  font-size: 0.75rem;
  padding-left: 1.5rem;
  color: RgbaColor($primary-dark, 0.4);
}

.comment {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.625rem;
  padding: 0.75rem 1rem;
  color: RgbaColor($primary-dark, 0.03);
}

.commentContent {
  display: grid;
  grid-gap: 0.375rem;
  font-size: 0.875rem;
}

.commentUser {
  display: flex;
  min-width: 0;
}

.commentName {
  font-weight: 600;
  color: $primary-dark;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.commentDate {
  font-weight: 700;
  font-size: 0.75rem;
  white-space: nowrap;
  color: RgbaColor($primary-dark, 0.4);
}

.commentText {
  font-weight: 500;
  color: RgbaColor($primary-dark, 0.8);
}

.commentInputWrapper {
  display: flex;
  padding: 1rem 1rem;
  background-color: #ebf0f0;

  .input {
    flex-grow: 1;
    font-weight: 500;
    border: 1px solid transparent;
    padding: 0.375rem 0.5rem;
    border-radius: 0.25rem;
    background-color: #ffffff;

    &::placeholder {
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.625;
      color: RgbaColor($primary-dark, 0.4);
    }

    &:hover,
    &:focus {
      box-shadow: none;
      border-color: RgbaColor($color-primary-blue-main, 0.5);
      background-color: #ffffff;
    }
  }
}

.avatarWrapper {
  margin-right: 1rem;
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5rem;
  padding: 1rem 1.5rem;
  color: RgbaColor($primary-dark, 0.4);
}

.emptyTitle {
  font-weight: 500;
  font-size: 0.875rem;
}
