.root {
  display: flex;
  flex-direction: column;
}

.root.disabled,
.root.disabled .dropdownContainer,
.root.disabled .selectedValue > svg {
  cursor: not-allowed;
}

.root.disabled .dropdownHeader {
  pointer-events: none;
  /*opacity: 0.5;*/
}

.root > * + * {
  margin-top: 8px;
}

.dropdownContainer {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 150px;
  height: 46px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
}

.dropdownHeader {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}

.dropdownHeader > svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.dropdownHeader .searchIcon {
  left: 15px;
}

.dropdownHeader .chevron {
  right: 15px;
}

.dropdownHeader input {
  padding-inline: 49px;
}

.dropdownHeader.open .chevron {
  transform-origin: top;
  transform: rotate(180deg) translateY(-50%);
}

.dropdown {
  position: absolute;
  inset: 100% 0 auto 0;
  margin-right: 4px;
  width: 100%;
  max-height: 360px;
  list-style-type: none;
  z-index: 10;
  border-radius: 8px;
  background: var(--primary-white, #fff);
  box-shadow: 0 6px 17px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding-inline: 12px;
  padding-bottom: 14px;
}

.dropdownList {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 250px;
  overflow-y: auto;
}

.dropdown > button {
  margin-top: 8px;
}

.dropdown label {
  display: block;
  padding: 16px 22px 14px;
  color: var(--neutrals-dark-40, rgba(0, 62, 71, 0.4));
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.dropdown-list-item {
  cursor: pointer;
}

.selectedValues {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.selectedValue {
  position: relative;
  text-decoration: none;
}

.selectedValue > svg path {
  fill: #003e47;
}

.selectedValue > svg {
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
}
