@import "../../../../../global-ui-scss/mixins";
@import "../../../../../global-ui-scss/variables";

.popover {
  :global(.ant-popover-inner) {
    display: flex;
    padding: 0.25rem;
  }

  .list {
    display: flex;
    grid-gap: 0.25rem;
  }

  .colorPickerItem {
    position: relative;
    width: 14px;
    height: 14px;
    border-radius: 2px;
    cursor: pointer;
    overflow: hidden;
    border: 1px solid #ebf0f0;
  }

  .colorPickerItemReset {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 1px;
      height: 17px;
      background-color: $color-red-700;
      transform: rotate(45deg) translate(-2px, -1px);
    }
  }
}
