/* .first-title {
  display: grid;
  grid-template-columns: 1fr max-content;
  margin-top: 60px;
  padding: 60px;
} */

.first-title {
  display: grid;
  grid-template-columns: 1fr 314px;
  margin: 60px 0 72px;
  padding: 20px 110px 0 95px;
  align-items: flex-end;
}

.first-title a {
  text-decoration: unset;
}

.first-title .more-time .title {
  font-weight: 800;
  font-size: 40px;
  line-height: 110%;
  color: #fff;
}

.text-first-block {
  margin-bottom: 35px;
  margin-top: 32px;
  line-height: 148%;
  max-width: 400px;
}

.right-block-content .second-text-block {
  color: rgba(255, 255, 255, 0.8);
  line-height: 148%;
}

.times-wrapper .video_wrapper video {
  max-width: 1114px;
  margin: 0 auto;
  border-radius: 12px;
  display: block;
  max-height: 504px;
  object-fit: contain;
  border: solid 5px rgba(0, 0, 0, 0.1);
  filter: brightness(0.2);
  transition: 0.4s;
  transform: scale(1);
}

.times-wrapper .video_wrapper {
  position: relative;
  transition: 0.4s;
}

.times-wrapper .video_wrapper_active {
  z-index: 100;
  /* position: relative; */
  position: fixed;
  top: 63px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 37, 42, 0.8);
}

.times-wrapper .video_wrapper_active video {
  /*transform: scale(1.2);*/
  filter: brightness(1);
  max-height: 75vh;
  max-width: 90%;
}
/* 
.times-wrapper .video_wrapper .wrapper_controls {
  position: absolute;
  inset: 0 0 0 0;
  width: max-content;
  display: grid;
  align-items: center;
  grid-template-columns: max-content 1fr;
  margin: auto;
  height: max-content;
  cursor: pointer;
  filter: brightness(1.5);
} */

.video_wrapper .wrapper_controls {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  cursor: pointer;
  filter: brightness(1.5);
}

.video_wrapper_play {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 28px 0;
}

.times-wrapper .video_wrapper .wrapper_controls span {
  font-weight: 800;
  font-size: 32px;
  line-height: 110%;
  color: #fff;
}

.macbook {
  z-index: 1;
  height: 500px;
  /* padding: 30px 20px 10px 0; */
  /* padding: 40px 90px 0 0; */
  margin: -20px -20px -140px 0;
  object-fit: contain;
}

.second-title .left-block {
  width: 552px;
  height: 465px;
  background: #00343c;
  border-radius: 16px;
}

.girl {
  position: relative;
  right: 25px;
  margin-top: 80px;
}

.second-title {
  display: grid;
  grid-template-columns: max-content 1fr;
  margin-top: 160px;
  align-items: center;
}

.right-block-content {
  margin-left: -70px;
}

.right-partner {
  font-weight: 800;
  font-size: 32px;
}

.return-time {
  margin-top: 200px;
}

.block-return-time {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 60px;
  grid-gap: 24px;
  align-items: start;
}

.title-return-time {
  font-weight: 100;
  font-size: 100px;
  color: #f17f16;
}

.second-title-return-time {
  color: #f17f16;
  font-weight: 600;
  font-size: 16px;
}

.blocks-return-time1,
.blocks-return-time2,
.blocks-return-time3 {
  display: grid;
  grid-gap: 15px;
  padding: 20px;
  max-width: 250px;
}

.title-return-time span {
  font-size: 28px;
}

.third-title-return-time {
  font-weight: 500;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  line-height: 148%;
}

.your-revision {
  padding: 40px;
  display: grid;
  justify-items: center;
  align-items: center;
}

.your-revision-info {
  display: grid;
  height: max-content;
  text-align: center;
}

.your-revision-info .btn_orange {
  justify-self: center;
}

.revision-text {
  color: rgba(255, 255, 255, 0.8);
  margin-top: 10px;
  margin-bottom: 30px;
}

.elipses {
  margin-top: 40px;
}

.reviews {
  margin-top: 90px;
  position: relative;
}

.navigation-reviews {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-vector {
  border-radius: 50%;
  padding: 15px;
  border: 2px solid #f17f16;
  cursor: pointer;
  background-color: #003e47;
  margin-left: 14px;
}

.review {
  font-weight: 300;
  font-size: 33px;
  line-height: 140%;
}

.review1 {
  padding: 50px 84px;
  max-width: 900px;
}

.reviews .slick-list {
  padding: 0 228px !important;
  margin: 0 -50px;
}

/* slide when not active/center*/
.reviews .sliders .slick-slide,
.reviews
  .sliders
  .slick-slide[aria-hidden="true"]:not(.slick-cloned)
  ~ .slick-cloned[aria-hidden="true"] {
  opacity: 0.2;
  /*transform: scale(.8);*/
  transition: 0.4s;
}

/* slide when active/center */
.reviews .sliders .slick-center,
.reviews
  .sliders
  .slick-slide[aria-hidden="true"]:not([tabindex="-1"])
  + .slick-cloned[aria-hidden="true"] {
  transform: scale(1);
  opacity: 1;
}

/* .reviews .sliders .slick-slide {
} */

/* this is causing overflow in the homepage */
/* .slick-list {
  min-width: 1233px;
} */

.slick-current {
  max-width: 990px;
}

/*font-weight: 300;
font-size: 28px;*/

.reviews .slick-active {
  opacity: 1;
  transition: opacity 0.8s;
}

.from-who-review {
  font-size: 16px;
  line-height: 60%;
  text-align: end;
  color: rgba(255, 255, 255, 0.6);
}

.times-wrapper .slick-prev:hover,
.times-wrapper .slick-prev:before,
.times-wrapper .slick-next:hover,
.times-wrapper .slick-next:before {
  display: none;
}

.times-wrapper .slick-prev {
  position: absolute;
  right: 70px !important;
  left: unset !important;
  top: -50px !important;
  border: 2px solid #f17f16;
  border-radius: 50%;
  padding: 25px;
  background-color: #003e47;
  background: url("../../assets/images/vector-left.svg") no-repeat center !important;
}

.times-wrapper .slick-next {
  position: absolute;
  right: 5px !important;
  left: unset !important;
  top: -50px !important;
  border: 2px solid #f17f16;
  border-radius: 50%;
  padding: 25px;
  background-color: #003e47;
  background: url("../../assets/images/vector-right.svg") no-repeat center !important;
}

.elipses {
  display: grid;
  position: relative;
  grid-template-columns: repeat(5, 1fr);
}

.elipses-photos {
  border: 27px solid #00343c;
  background-color: #00343c;
  border-radius: 50%;
  width: 91px;
  height: 91px;
  display: flex;
  justify-content: center;
}

.title-for-elipses {
  font-weight: 800;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #ffffff;
}

.elipses__every {
  padding: 19px;
  display: grid;
  justify-items: center;
  position: relative;
}

.elipses__every .elipses-photos {
  position: relative;
  z-index: 1;
}

.elipses .line {
  position: absolute;
  top: 35px;
  margin-left: 100px;
}

.wrapper_differently_block {
  margin: 100px 0;
  display: grid;
  grid-template-columns: 400px 1fr;
  grid-gap: 20px;
}
.close_video {
  cursor: pointer;
}

.wrapper_differently_block .title_wrapper {
  display: grid;
  grid-gap: 15px;
  margin-bottom: -35px;
}

.wrapper_differently_block .title_wrapper .title {
  font-weight: 800;
  font-size: 32px;
  line-height: 110%;
  color: #fff;
  margin-top: -10px;
}

.wrapper_differently_block .title_wrapper .description {
  font-weight: 500;
  font-size: 16px;
  line-height: 148%;
  color: rgba(255, 255, 255, 0.8);
}

.wrapper_differently_block .info {
  grid-gap: 120px;
  display: grid;
}

.wrapper_differently_block__every {
  display: grid;
}

.wrapper_differently_block__every .title {
  color: rgba(255, 255, 255, 0.2);
  transition: 0.4s;
  word-break: break-word;
  font-weight: 800;
  font-size: 20px;
  line-height: 110%;
}

.wrapper_differently_block__every .description {
  color: rgba(255, 255, 255, 0.2);
  transition: 0.4s;
  font-weight: 500;
  font-size: 16px;
  line-height: 148%;
}

.wrapper_differently_block__every_active .title {
  color: #fff;
  word-break: break-word;
  font-weight: 800;
  font-size: 20px;
  line-height: 110%;
}

.wrapper_differently_block__every_active .description {
  font-weight: 500;
  font-size: 16px;
  line-height: 148%;
  color: rgba(255, 255, 255, 0.8);
}

.wrapper_differently_block .img_block {
  display: grid;
  justify-content: center;
}

.wrapper_differently_block .img_block .circle_center {
  position: -webkit-sticky;
  position: sticky;
  top: 130px;
  width: 560px;
  height: 560px;
  border: 85px solid #00343c;
  border-radius: 50%;
}

.wrapper_differently_block .img_block .circle_center .every_img {
  position: absolute;
}

.wrapper_differently_block .img_block .circle_center .every_img_first_block {
  left: -100px;
  top: -100px;
}

.wrapper_differently_block .img_block .circle_center .every_img_second_block {
  right: -160px;
  top: -80px;
}

.wrapper_differently_block .img_block .circle_center .every_img_third_block {
  bottom: -100px;
  left: 20%;
}

.wrapper_differently_block .img_block .circle_center .every_img p {
  background: #00252a;
  padding: 4px 14px;
  border-radius: 38px;
  font-weight: 500;
  font-size: 16px;
  line-height: 148%;
  opacity: 0.4;
  color: #fff;
  transition: 0.4s;
  margin: 0 auto;
  width: max-content;
  margin-top: 18px;
}
.wrapper_differently_block .img_block .circle_center .every_img_active p {
  opacity: 1;
}

.wrapper_differently_block .img_block .circle_center .every_img .opacitySVG {
  opacity: 0.4;
  transition: 0.2s;
}

.wrapper_differently_block .img_block .circle_center .every_img_active .opacitySVG {
  opacity: 1;
}

/*.elipses .line work to 900*/

@media screen and (max-width: 1200px) {
  .landing__wrapper {
    padding: 0;
  }

  .wrapper_differently_block .img_block .circle_center {
    border: unset;
  }

  .wrapper_differently_block .img_block .circle_center .every_img {
    position: unset;
  }

  .wrapper_differently_block .img_block .circle_center .every_img_second_block,
  .wrapper_differently_block .img_block .circle_center .every_img_first_block {
    left: unset;
    top: unset;
  }

  .wrapper_differently_block .img_block .circle_center .every_img_third_block {
    bottom: unset;
    left: unset;
  }

  .wrapper_differently_block .img_block {
    align-content: end;
  }

  .wrapper_differently_block .img_block .circle_center {
    position: unset;
    grid-gap: 80px;
    display: grid;
    width: unset;
    height: unset;
  }
}

@media screen and (max-width: 1024px) {
  .slick-list {
    min-width: 973px;
  }

  .slick-current {
    max-width: 820px;
  }
}

@media screen and (max-width: 1000px) {
  .first-title img {
    height: 500px;
  }

  .times-wrapper video {
    margin-top: 0;
  }

  .second-title {
    grid-template-columns: 1fr;
  }

  .second-title .right-block-content {
    grid-row: 1;
    margin-left: 0;
  }

  .second-title .left-block {
    margin: 0 auto;
    display: grid;
    align-items: center;
  }

  .second-title .left-block img {
    right: unset;
    margin: 0 auto;
  }

  .block-return-time {
    grid-template-columns: 1fr 1fr;
    padding: 48px 0 0;
  }

  .reviews .review {
    font-size: 28px;
  }

  .reviews .navigation-reviews h1 {
    font-weight: 800;
    font-size: 24px;
  }
}

@media screen and (max-width: 900px) {
  .first-title {
    grid-template-columns: 1fr 232px;
  }

  .review1 {
    padding: 50px 48px;
  }

  .reviews .slick-list {
    padding: 0 184px !important;
  }
  /* .review {
    display: inline-block;
    width: 80%;
  } */

  .first-title img {
    height: 400px;
  }

  .times-wrapper video {
    width: 100%;
    max-height: unset;
  }

  .wrapper_differently_block {
    grid-template-columns: 1fr 1fr;
  }

  .your-revision {
    grid-template-columns: 1fr max-content;
    padding: 0;
    align-items: start;
  }

  .your-revision .elipses {
    grid-template-columns: 1fr;
    justify-self: end;
    margin-top: 0;
  }

  /* old version */
  /* .your-revision .elipses .line {
    margin-left: unset;
    transform: rotate(90deg);
    top: 364px;
    left: -202px;
  } */

  .your-revision .elipses .line {
    margin-left: unset;
    transform: rotate(90deg);
    top: 320px;
    left: -209px;
    /* margin-left: -80px; */
    /* height: 100px; */
  }
  .elipses {
    grid-gap: 20px;
  }

  .your-revision .title-for-elipses {
    position: relative;
    z-index: 10;
  }

  .elipses__every {
    display: grid;
    grid-template-columns: max-content 1fr;
  }

  .your-revision .title-for-elipses {
    width: 50%;
    margin-left: -10px;
  }

  .your-revision-info {
    display: grid;
    height: max-content;
    text-align: left;
  }

  .your-revision-info .btn_orange {
    display: none;
  }
}

@media screen and (max-width: 820px) {
  .first-title {
    grid-template-columns: 1fr;
    grid-gap: 40px;
    margin-top: 85px;
    padding: unset;
  }
  /*.reviews .review {*/
  /*  position: relative;*/
  /*  left: -1%;*/
  /*  display: block;*/
  /*  width: 500px;*/
  /*}*/

  /*.from-who-review {*/
  /*  position: relative;*/
  /*  left: -260px;*/
  /*}*/

  .first-title .macbook {
    margin: -150px -78px -140px auto;
  }

  .times-wrapper .video_wrapper_active {
    top: 62px;
  }

  .times-wrapper .video_wrapper_active video {
    max-width: 95%;
  }
}

@media screen and (max-width: 768px) {
  .first-title {
    grid-template-columns: 1fr;
    grid-gap: 40px;
    margin-top: 85px;
    padding: unset;
  }

  .first-title img {
    justify-self: end;
    height: 450px;
    margin-top: -40px;
  }

  .times-wrapper video {
    margin-top: -60px;
  }

  .second-title .left-block {
    max-width: 425px;
    max-height: 334px;
    margin-left: -40px;
  }

  .girl {
    max-width: 280px;
    max-height: 284px;
    right: 0;
    margin-left: -6px;
    margin-top: 60px;
  }

  .right-block-content {
    margin-top: 50px;
    margin-bottom: 40px;
  }

  .right-block-content .right-partner {
    font-size: 24px;
  }

  .right-block-content p {
    font-size: 16px;
  }

  .return-time {
    margin-top: 140px;
  }

  .block-return-time {
    grid-template-columns: 1fr 1fr;
  }

  /*.wrapper_differently_block .title_wrapper .title{*/
  /*    font-size: 24px;*/
  /*}*/
  .reviews .review {
    font-size: 20px;
  }

  .reviews .slick-list {
    padding: 0 154px !important;
  }

  .review1 {
    padding: 50px 32px;
    max-width: 480px;
  }

  .slick-list {
    min-width: 710px;
  }

  .slick-current {
    max-width: 530px;
  }

  .reviews .navigation-reviews h1 {
    font-weight: 800;
    font-size: 24px;
    width: 60%;
  }

  .times-wrapper .slick-prev,
  .times-wrapper .slick-next {
    top: -35px !important;
  }

  .wrapper_differently_block {
    grid-template-columns: 1fr;
  }

  .wrapper_differently_block__every .every_img_active {
    margin: 0 auto;
  }

  .second-title {
    margin-top: 70px;
  }

  .wrapper_open_menu .menu_wrapper span {
    margin-top: 20px;
  }
  .first-title .macbook {
    /* margin: -43px -111px 20px 0; */
    margin: -100px -78px -140px auto;
  }

  .times-wrapper .video_wrapper_active {
    top: 59px;
  }
}

@media screen and (max-width: 600px) {
  .reviews .slick-list {
    padding: 0 200px !important;
  }

  .your-revision .elipses {
    justify-self: center;
  }
  /* .review {
    display: inline-block;
    text-align: left;
    width: 250px;
  } */
  .first-title .more-time .title {
    font-size: 35px;
  }

  .first-title img {
    width: 100%;
    height: unset;
    margin-top: -80px;
  }

  .your-revision {
    grid-template-columns: 1fr;
  }

  .block-return-time {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .second-title .left-block {
    width: 100%;
    height: max-content;
  }

  .your-revision .elipses {
    margin-top: 60px;
  }

  .elipses__every {
    grid-template-columns: 91px 1fr;
    grid-gap: 20px;
  }

  .your-revision .title-for-elipses {
    text-align: left;
    align-self: center;
    width: 100px;
  }

  .your-revision .elipses .line {
    top: 320px;
  }

  .times-wrapper .video_wrapper_active video {
    max-width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .first-title .macbook {
    /* margin: -43px -111px 20px 0; */
    /* margin: -100px -140px 60px 0; */
    width: 320px;
    margin: -68px -64px -120px 0;
  }

  .review1 {
    padding: 42px 20px;
    max-width: unset;
  }

  .slick-list {
    min-width: unset;
  }

  .slick-current {
    max-width: unset;
  }
  .reviews .review {
    font-size: 20px;
  }

  .reviews .slick-list {
    padding: 0 48px !important;
  }

  .wrapper_differently_block .title {
    margin-top: 39px;
    margin-bottom: 0;
  }
  .wrapper_differently_block .title_wrapper {
    margin-bottom: 30px;
  }
  .wrapper_differently_block .info {
    grid-gap: 80px;
    display: grid;
  }

  .times-wrapper .video_wrapper .wrapper_controls {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    cursor: pointer;
    filter: brightness(1.5);
  }

  .your-revision-info {
    display: grid;
    height: max-content;
    text-align: center;
  }

  .your-revision-info .btn_orange {
    display: none;
  }
}

@media screen and (max-width: 380px) {
  .wrapper_controls span {
    font-size: 30px !important;
    white-space: nowrap;
  }
  .first-title .macbook {
    width: 275px;
    margin: -68px -78px -120px 0;
  }
}
